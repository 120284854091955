<template>
	<div class="h-100">
		<b-card :header="'ТПА №'+ call.machine" class="mb-1 h-100 shadow" :border-variant="variant" :header-bg-variant="variant">
			<b-card-text>
				<div>{{call.calltype=='main' ? 'Вызов' : 'Доп.вызов'}}  №{{call.id}}: {{dateCall}}</div>
				<div v-if="call.date_start">Начало: {{ dateStart }}</div>
				<div v-if="call.reason">Причина вызова: {{ call.reason }}</div>

				<div v-if="call.calltype=='main'">
					<div class="mt-2" v-if="!appointed">
						<label>Назначить:</label>
						<div class="input-group">
							<b-select class="form-control" v-model="coworker" :options="coworkers" value-field="guid" html-field="ФИО"></b-select>
							<div class="input-group-append">
								<span class="input-group-text pointer" title="Назначить" @click="setCoworker"><b-icon-box-arrow-in-right/></span>
							</div>
						</div>
					</div>
					<div v-else>
						Назначен {{coworkerName}}
					</div>
				</div>

				<div v-if="call.calltype=='main'" class="mt-2">
					<label>Перенаправить:</label>
					<div class="input-group">
						<b-select class="form-control" v-model="forwardedService" :options="this.$store.state.dicts.Службы" value-field="id" html-field="name"></b-select>
						<div class="input-group-append">
							<span class="input-group-text pointer" title="Перенаправить" v-b-modal="'modal-forward-call'+this.call.id"><b-icon-box-arrow-right/></span>
						</div>
						<b-modal
							:id="'modal-forward-call'+this.call.id"
							title="Перенаправить"
							ok-title="Сохранить"
							cancel-title="Отмена"
							@show="checkForwardCallService"
							@ok="forwardServiceCall"
							:ok-disabled="!forwardedReason">
							<b-select v-if="forwardedService&&forwardedService==3" class="form-control" v-model="forwardedReason" :options="OTKReasons" value-field="name" html-field="name"></b-select>
							<b-form-input v-else v-model="forwardedReason" placeholder="Введите причину"></b-form-input>
						</b-modal>
					</div>
				</div>

				<div v-if="call.calltype=='main'" class="mt-2">
					<label>Доп.вызов:</label>
					<div class="input-group">
						<b-select class="form-control" v-model="subCallService" :options="this.$store.state.dicts.Службы" value-field="id" html-field="name"></b-select>
						<div class="input-group-append">
							<span class="input-group-text pointer" title="Добавить" v-b-modal="'modal-start-subcall'+this.call.id"><b-icon-person-plus-fill/></span>
						</div>
						<b-modal
							:id="'modal-start-subcall'+this.call.id"
							title="Добавить доп.вызов"
							ok-title="Добавить"
							cancel-title="Отмена"
							@show="checkSubCallService"
							@ok="addServiceSubCall"
							:ok-disabled="!subCallReason">
							<b-select v-if="subCallService&&subCallService==3" class="form-control" v-model="subCallReason" :options="OTKReasons" value-field="name" html-field="name"></b-select>
							<b-form-input v-else v-model="subCallReason" placeholder="Введите причину"></b-form-input>
						</b-modal>
					</div>
				</div>

			</b-card-text>
		</b-card>
	</div>
</template>

<script>
export default {
	name: "CallCard",
	mounted(){
		if(this.call.coworker) {
			this.appointed = true
		}
	},
	data(){
		return {
			coworker: null,
			forwardedService: null,
			subCallService: null,
			appointed: false,
			subCallReason: null,
			forwardedReason: null,
			OTKReasons: [{value: null, text:"Выберите причину вызова", disabled: true}, "Запуск", "Переход цвета", "Дефект устранён"],
		}
	},
	props: {
		call: Object		
	},
	computed: {
		variant(){
			if(!this.call.date_start) return 'danger'
			if(this.call.date_start) return 'success'
		},
		dateCall(){
			let date = new Date(this.call.date_call)
			return date.toLocaleTimeString('ru-RU', {hour: '2-digit', minute: '2-digit'})
		},
		dateStart(){
			let date = new Date(this.call.date_start)
			return date.toLocaleTimeString('ru-RU', {hour: '2-digit', minute: '2-digit'})
		},
		coworkers(){
			return this.$store.state.dicts[this.$route.meta.coworkers]
		},
		coworkerName: function(){
			if(!this.coworkers) return ""
			let name = this.coworkers.filter((c)=>c.guid==this.call.coworker)[0].ФИО
			let shortName = name.replace(/(\S+) (\S)\S* (\S)\S*/, "$1 $2.$3.")
			shortName = shortName.replace("&#9733;","")
			return shortName
		},
	},
	methods: {
		setCoworker(){
			if(!this.coworker) return
			this.$store.dispatch('saveCall', {id: this.call.id, sn: this.call.sn, coworker: this.coworker}).then(()=>{
				this.appointed = true
			})
		},
		forwardServiceCall(){
			//if(!this.forwardedService || this.call.service==this.forwardedService) return
			let call = {id: this.call.id, sn: this.call.sn, forwardedService: this.forwardedService, reason: this.forwardedReason}
			this.$store.dispatch('saveCall', call)
			this.forwardedService = null
			this.forwardedReason = null
		},
		addServiceSubCall(){
			let subcall = {maincall: this.call.id, service: this.subCallService, reason: this.subCallReason}
			this.$store.dispatch('saveSubCall', subcall)
			this.subCallService = null
			this.subCallReason = null
		},
		checkSubCallService(bvModalEvent){
			if(!this.subCallService) {
				bvModalEvent.preventDefault()
			}
		},
		checkForwardCallService(bvModalEvent){
			if(!this.forwardedService) {
				bvModalEvent.preventDefault()
			}
		},
	}
}
</script>

<style scoped>
	
</style>