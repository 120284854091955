import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex)
Vue.use(VueAxios, axios)

const store = new Vuex.Store({
	state: {
		dicts: [],
		tasks: [],
		repairs: [],
		moves: [],
		calls: [],
		areas: [],
		token: '',
		nl: null,
		brigadier: null,
		area: null,
		completedVisible: false,
		settingsOpened: false,
		minimized: false,
		loading: false,
		alertText: ""
	},
	mutations: {
		setAlertText: (state,payload) => {
			state.alertText = payload
		},
		setDicts: (state,payload) => {
			state.dicts = payload
			//Добавим пустое значение
			state.dicts.Наладчики.unshift({guid:null,ФИО:"Выберите сотрудника",disabled: true})
			state.dicts.Участки.unshift({ТПАGUID:null,name:"Выберите ТПА",disabled: true})
			//Добавим бригадиру звездочку
			let brigadier = state.dicts.Наладчики[state.dicts.Наладчики.findIndex(el => el.Роль === 'бригадир')]
			if(brigadier){
				brigadier.ФИО += ' &#9733;'
				state.brigadier = brigadier.guid
			}
			state.dicts.Инструментальщики.unshift({guid:null,ФИО:"Выберите сотрудника",disabled: true})
			state.dicts.Мастера.unshift({guid:null,ФИО:"Выберите сотрудника",disabled: true})
			state.dicts.Откашники.unshift({guid:null,ФИО:"Выберите сотрудника",disabled: true})
			state.dicts.Комплектовщики.unshift({guid:null,ФИО:"Выберите сотрудника",disabled: true})
			state.dicts.Электромонтеры.unshift({guid:null,ФИО:"Выберите сотрудника",disabled: true})
			state.dicts.Механики.unshift({guid:null,ФИО:"Выберите сотрудника",disabled: true})
			state.dicts.Службы.unshift({id:null,name:"Выберите службу",disabled: true})
		},
		setTasks: (state,payload) => {
			let arrComleted = ['Завершено','Отмена','Ремонт']
			payload.forEach(function(el){
				el.completed = arrComleted.includes(el.СтатусЗамены)
				if(el.СтатусЗамены=='Выдано мастером литья') {
					el.stage = 1
				} else if(el.СтатусЗамены=='Взято в работу') {
					el.stage = 2
				} else if(!el.НазначенЗапуск_Сотрудник_guid && el.СтатусЗамены=='Выполнен завес') {
					el.stage = 3
				} else if(el.НазначенЗапуск_Сотрудник_guid && el.СтатусЗамены=='Выполнен завес') {
					el.stage = 4
				} else {
					el.stage = 5
				}

				//Исключение для запуска без завеса
				if(el.stage<3 && el.ВидЗадания=='Запуск без завеса') el.stage = 3
				if(el.ВидЗадания=='Запуск без завеса' && el.СтатусЗамены=='Взято в работу' && el.НазначенЗапуск_Сотрудник_guid){
					el.stage = 4
					el.СтатусЗамены = 'Выполнен завес'
				}

				let date = new Date()
				let hours = date.getHours()
				if(hours>=0 && hours<8) {
					date.setDate(date.getDate() - 1)
					date.setHours(20,0,0)
				} else if(hours>=8 && hours<20){
					date.setHours(8,0,0)
				} else{
					date.setHours(20,0,0)
				}				
				if(el.НужноВключение=='Да' && el.completed && new Date(el.ВыполненЗапуск_Время)<date){
					el.completed = false
				}
			})
			state.tasks = payload
		},
		setRepairs: (state,payload) => {			
			state.repairs = payload
		},
		setMoves: (state,payload) => {			
			state.moves = payload
		},
		setCalls: (state,payload) => {			
			state.calls = payload
		},
		setAreas: (state, payload) => {
			let areas = []
			//Добавим пустое значение
			areas.push({value: null, text:'Все'})	
			//Заполним участки		
			payload.forEach((element) => {
				if(!areas.includes(element.Участок)) {areas.push(element.Участок)}
			})
			state.areas = areas
			let found = areas.find((el) => {
				if(el==store.state.area) return true
			})			
			if(found==undefined){				
				state.area = null
			}
		},
		setNl: (state,payload) => {			
			state.nl = (payload==undefined?null:payload)
		},
		setArea: (state,payload) => {			
			state.area = (payload==undefined?null:payload)
		},
		setVisibility: (state,payload) => {			
			state.completedVisible = payload
		},
		setSettingsOpened: (state,payload) => {			
			state.settingsOpened = payload
		},
		setMinimized: (state,payload) => {			
			state.minimized = payload
		},
		setToken: (state, payload) => {
			state.token = payload
		}
	},
	actions: {
		getToken:(context) => {
			if (localStorage.getItem('auth')) {
				let auth = JSON.parse(localStorage.getItem('auth'))
				context.commit('setToken', auth.token)
			}
		},
		getDicts:(context,payload) => {
			store.state.loading = true
			let updateDicts = () => {
				axios
				.get('https://nl.9330077.ru/getDicts.php')
				.then(function (response) {
					context.commit('setDicts',response.data)
				})
				.then(function(){
					//Если наладчик не найден в смене или не заполнен откроем настройки и сбросим значение в хране
					let arr = store.state.dicts.Наладчики
					let found = arr.find((el) => {
						if(el.guid==store.state.nl) return true
					})				
					if(found==undefined || !store.state.nl){
						context.commit('setNl',null)
						context.commit('setSettingsOpened',true)
					}
				})
				.then(function(){
					store.state.loading = false
				})
			}
			updateDicts()
			setInterval(updateDicts, 300000)
		},
		getTasks:(context,payload) => {
			store.state.loading = true
			let updateTasks = () => {
				axios
				.get('https://nl.9330077.ru/getTasks.php')
				.then(function (response) {
					context.commit('setTasks',response.data)
					context.commit('setAreas',response.data)
				})
				.then(function(){
					store.state.loading = false
					//console.log('tasks updated')
				})
			}
			updateTasks()
			setInterval(updateTasks, 300000)
		},
		getRepairs:(context,payload) => {
			store.state.loading = true
			let updateRepairs = () => {
				axios
				.get('https://nl.9330077.ru/getRepairs.php')
				.then(function (response) {
					context.commit('setRepairs',response.data)
				})
				.then(function(){
					store.state.loading = false
				})
			}
			updateRepairs()
			setInterval(updateRepairs, 300000)
		},
		getMoves:(context,payload) => {
			store.state.loading = true
			let updateMoves = () => {
				axios
				.get('https://nl.9330077.ru/getMoves.php')
				.then(function (response) {
					context.commit('setMoves',response.data)
				})
				.then(function(){
					store.state.loading = false
				})
			}
			updateMoves()
			setInterval(updateMoves, 300000)
		},
		getCalls:(context,payload) => {
			store.state.loading = true
			let updateCalls = () => {
				axios
				.get('https://nl.9330077.ru/getCalls.php')
				.then(function (response) {
					context.commit('setCalls',response.data)
				})
				.then(function(){
					store.state.loading = false
				})
			}
			updateCalls()
			setInterval(updateCalls, 10000)
		},
		saveTask: (context,payload) => {
			let arr = []
			arr.push({
				guid: payload.guid, 
				om_guid: payload.om_guid,
				nl_guid: store.state.nl,
				oper: payload.newStatus,
				test: payload.test,
				wop: payload.wop,
				hanger: (payload.newStatus=='start'?payload.hanger:null),
				launcher: (['start', 'startlaunch'].includes(payload.newStatus)?payload.launcher:null),
			})
			let parsed = JSON.stringify(arr)
			let formData = new FormData()
			formData.append('task', parsed)
			formData.append('token', store.state.token)

			axios({
				method: 'post',
				url: 'https://nl.9330077.ru/saveTask.php',
				data: formData
				})
			.then(response => {
				store.dispatch('getDicts')
				store.dispatch('getTasks')
			})
		},
		saveRepair: (context,payload) => {
			let arr = []
			arr.push({
				guid: payload.remont_guid,
				action: payload.action,
				repairer: payload.repairer
			})
			let parsed = JSON.stringify(arr)
			let formData = new FormData()
			formData.append('repair', parsed)
			formData.append('token', store.state.token)

			axios({
				method: 'post',
				url: 'https://nl.9330077.ru/saveRepair.php',
				data: formData
			}).then(response => {
				store.dispatch('getRepairs')
			})
		},
		startManualPPR: (context,payload) => {
			let arr = []
			arr.push({
				tpa_guid: payload,
			})
			let parsed = JSON.stringify(arr)
			let formData = new FormData()
			formData.append('repair', parsed)
			formData.append('token', store.state.token)

			axios({
				method: 'post',
				url: 'https://nl.9330077.ru/startManualPPR.php',
				data: formData
			}).then(response => {
				store.commit('setAlertText', response.data.result)
			})
		},
		saveCall: (context, payload) => {
			axios
			.post('https://nl.9330077.ru/saveCall.php', {"token":store.state.token, "call":payload},{headers: {'Content-Type':'application/x-www-form-urlencoded'}})
			.then(response => {
				store.dispatch('getCalls')
			})
		},
		saveSubCall: (context, payload) => {
			axios
			.post('https://nl.9330077.ru/saveSubCall.php', {"token":store.state.token, "subcall":payload},{headers: {'Content-Type':'application/x-www-form-urlencoded'}})
			.then(response => {
				console.log(response)
			})
		},
		startRepair: (context, payload) => {
			let arr = []
			arr.push({
				mold_guid: payload.mold_guid,
				repairType: payload.repairType,
				repairReason: payload.repairReason,
				TPA: payload.TPA,
			})
			let parsed = JSON.stringify(arr)
			let formData = new FormData()
			formData.append('repair', parsed)
			formData.append('token', store.state.token)

			axios({
				method: 'post',
				url: 'https://nl.9330077.ru/startRepair.php',
				data: formData
			}).then(response => {
				store.commit('setAlertText', response.data.result)
			})
		},
		startDelayedRepair: (context, payload) => {
			let arr = []
			arr.push({
				mold_guid: payload.mold_guid,
				repairType: payload.repairType,
				repairReason: payload.repairReason,
				TPA: payload.TPA,
				tpa_guid: payload.tpa_guid
			})
			let parsed = JSON.stringify(arr)
			let formData = new FormData()
			formData.append('repair', parsed)
			formData.append('token', store.state.token)

			axios({
				method: 'post',
				url: 'https://nl.9330077.ru/startDelayedRepair.php',
				data: formData
			}).then(response => {
				store.commit('setAlertText', response.data.result)
			})
		},
		saveMove: (context,payload) => {
			let arr = []
			arr.push({
				pf_guid: payload.pf_guid,
				action: payload.action,
				seat_guid: payload.seat_guid				
			})
			let parsed = JSON.stringify(arr)
			let formData = new FormData()
			formData.append('move', parsed)
			formData.append('token', store.state.token)

			axios({
				method: 'post',
				url: 'https://nl.9330077.ru/saveMove.php',
				data: formData
			}).then(response => {
				store.dispatch('getDicts')
				store.dispatch('getMoves')
			})
		},
		hideMove: (context,payload) => {
			let formData = new FormData()
			formData.append('guid', payload.pf_guid)
			formData.append('time', payload.ОкончаниеРемонта)
			formData.append('token', store.state.token)

			axios({
				method: 'post',
				url: 'https://nl.9330077.ru/hideMove.php',
				data: formData
			}).then(response => {				
				store.dispatch('getMoves')
			})
		},
	},
	modules: {
	}
})

export default store
